define('ember-macaroni/macros/collection/reject-from-collection-by-value', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = rejectFromCollectionByValue;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var emberArray = _ember['default'].A;

  /**
   * Returns an array with the items that do not match the passed value.
   *
   * Ember.Object.extend({
   *   items: [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }],
   *   selectedItem: rejectFromCollectionByValue('items', 'id', 2) // [{ id: 1, name: 'foo' }]
   * });
   *
   * @param {String} collectionKey The key name for the collection
   * @param {String} propName The key name for the property to reject by
   * @param {*} value The value to reject
  */

  function rejectFromCollectionByValue(collectionKey, propName, value) {
    return computed(collectionKey + '.@each.' + propName, {
      get: function get() {
        return emberArray(_get(this, collectionKey)).rejectBy(propName, value);
      }
    });
  }
});