define('ember-route-action-helper/-private/internals', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ACTION = undefined;


  var ClosureActionModule = void 0;

  if ('ember-htmlbars/keywords/closure-action' in _ember.default.__loader.registry) {
    ClosureActionModule = _ember.default.__loader.require('ember-htmlbars/keywords/closure-action');
  } else if ('ember-routing-htmlbars/keywords/closure-action' in _ember.default.__loader.registry) {
    ClosureActionModule = _ember.default.__loader.require('ember-routing-htmlbars/keywords/closure-action');
  } else {
    ClosureActionModule = {};
  }

  var ACTION = exports.ACTION = ClosureActionModule.ACTION;
});