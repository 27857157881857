define('ember-api-actions/index', ['exports', 'ember-api-actions/utils/member-action', 'ember-api-actions/utils/collection-action'], function (exports, _memberAction, _collectionAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.memberAction = exports.collectionAction = exports.instanceOp = exports.classOp = undefined;
  var classOp = exports.classOp = _collectionAction.default;
  var instanceOp = exports.instanceOp = _memberAction.default;

  exports.collectionAction = _collectionAction.default;
  exports.memberAction = _memberAction.default;
});