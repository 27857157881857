define('ember-macaroni/macros/truth/lte', ['exports', 'ember', 'ember-macaroni/macros/utils/compare'], function (exports, _ember, _emberMacaroniMacrosUtilsCompare) {
  exports['default'] = lte;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;

  /**
   * Lesser than or equal to comparison between two dependent keys.
   *
   * Ember.Object.extend({
   *   first: 5,
   *   second: 2,
   *   isFirstLesserThanOrEqualToSecond: lte('first', 'second') // false
   * });
   *
   * @param {String} lhsKey The key name for the left hand side of the operator
   * @param {String} rhsKey The key name for the right hand side of the operator
  */

  function lte(lhsKey, rhsKey) {
    return computed(lhsKey, rhsKey, {
      get: function get() {
        return (0, _emberMacaroniMacrosUtilsCompare['default'])(_get(this, lhsKey), _get(this, rhsKey), 'lte');
      }
    });
  }
});