define("ember-macaroni/macros/utils/compare", ["exports"], function (exports) {
  exports["default"] = compare;
  var comparisonMap = {
    gt: function gt(lhs, rhs) {
      return lhs > rhs;
    },
    gte: function gte(lhs, rhs) {
      return lhs >= rhs;
    },
    lt: function lt(lhs, rhs) {
      return lhs < rhs;
    },
    lte: function lte(lhs, rhs) {
      return lhs <= rhs;
    }
  };

  function compare(lhs, rhs, operator) {
    return !!comparisonMap[operator](lhs, rhs);
  }
});