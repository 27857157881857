define('ember-macaroni/macros/collection/filter-from-collection-by-contains', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = filterFromCollectionByContains;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var emberArray = _ember['default'].A;

  /**
   * Returns an array with just the items that are contained in another array.
   *
   * Ember.Object.extend({
   *   items: [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }],
   *   selectedId: 1,
   *   selectedItem: filterFromCollectionByContains('items', 'id', [1]) // [{ id: 1, name: 'foo' }]
   * });
   *
   * @param {String} collectionKey The key name for the collection
   * @param {String} propName The key name for the property to filter by
   * @param {Array} values The array of values to filter
  */

  function filterFromCollectionByContains(collectionKey, propName) {
    var values = arguments.length <= 2 || arguments[2] === undefined ? [] : arguments[2];

    return computed(collectionKey + '.@each.' + propName, {
      get: function get() {
        return emberArray(_get(this, collectionKey)).filter(function (item) {
          return emberArray(values).contains(_get(item, propName));
        });
      }
    });
  }
});