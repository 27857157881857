define('ember-macaroni/macros/general/join-with', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = joinWith;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;

  /**
   * Returns a string of values joined together with a separator.
   *
   * Ember.Object.extend({
   *   firstName: 'Derek',
   *   lastName: 'Zoolander',
   *   fullName: joinWith(' ', 'firstName', 'lastName') // 'Derek Zoolander'
   * });
   *
   * @param {String} seperator Separator to join values with
   * @param {...rest} dependentKeys Argument list of dependent keys
  */

  function joinWith(separator) {
    for (var _len = arguments.length, dependentKeys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      dependentKeys[_key - 1] = arguments[_key];
    }

    var computedFunc = computed({
      get: function get() {
        var _this = this;

        return dependentKeys.map(function (dependentKey) {
          return _get(_this, dependentKey);
        }).join(separator);
      }
    });

    return computedFunc.property.apply(computedFunc, dependentKeys);
  }
});