define('ember-multiselect-checkboxes/components/multiselect-checkboxes', ['exports', 'ember'], function (exports, _ember) {

  var Checkbox = _ember['default'].Object.extend({
    isSelected: _ember['default'].computed('value', 'selection.[]', {
      get: function get() {
        return this.get('selection').includes(this.get('value'));
      },

      set: function set(_, checked) {
        var selection = this.get('selection');
        var selected = selection.includes(this.get('value'));
        var onchange = this.get('onchange');
        var updateSelectionValue = this.get('updateSelectionValue');
        var isMutable = typeof selection.addObject === 'function' && typeof selection.removeObject === 'function';

        // Dispatch onchange event to handler with updated selection if handler is specified
        if (onchange) {
          var updated = _ember['default'].A(selection.slice());
          var operation = undefined;

          if (checked && !selected) {
            operation = 'added';
            updated.addObject(this.get('value'));
          } else if (!checked && selected) {
            operation = 'removed';
            updated.removeObject(this.get('value'));
          }

          onchange(updated, this.get('value'), operation);
        }

        // Mutate selection if updateSelectionValue is true and selection is mutable
        if (updateSelectionValue !== false && isMutable) {
          if (checked && !selected) {
            selection.addObject(this.get('value'));
          } else if (!checked && selected) {
            selection.removeObject(this.get('value'));
          }

          return checked;
        } else {

          // Only change the checked status of the checkbox when selection is mutated, because if
          // it is not mutated and the onchange handler does not update the bound selection value the
          // displayed checkboxes would be out of sync with bound selection value.
          return !checked;
        }
      }
    })
  });

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['multiselect-checkboxes'],

    tagName: 'ul',

    i18n: _ember['default'].computed(function () {
      return _ember['default'].getOwner(this).lookup('service:i18n');
    }),

    checkboxes: _ember['default'].computed('options.[]', 'labelProperty', 'valueProperty', 'selection', 'translate', 'i18n.locale', function () {
      var _this = this;

      var labelProperty = this.get('labelProperty');
      var valueProperty = this.get('valueProperty');
      var selection = _ember['default'].A(this.get('selection'));
      var onchange = this.get('onchange');
      var updateSelectionValue = this.get('updateSelectionValue') !== undefined ? this.get('updateSelectionValue') : true;
      var options = _ember['default'].A(this.get('options'));
      var translate = this.get('translate');

      var checkboxes = options.map(function (option) {
        var label = undefined,
            value = undefined;

        if (labelProperty) {
          if (typeof option.get === 'function') {
            label = option.get(labelProperty);
          } else {
            label = option[labelProperty];
          }
        } else {
          label = String(option);
        }

        if (translate && label && _this.get('i18n')) {
          label = _this.get('i18n').t(label);
        }

        if (valueProperty) {
          if (typeof option.get === 'function') {
            value = option.get(valueProperty);
          } else {
            value = option[valueProperty];
          }
        } else {
          value = option;
        }

        return Checkbox.create({
          option: option,
          label: label,
          value: value,
          selection: selection,
          onchange: onchange,
          updateSelectionValue: updateSelectionValue
        });
      });

      return _ember['default'].A(checkboxes);
    })
  });
});