define('ember-useragent/services/user-agent', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var isEqual = _ember['default'].isEqual;
  var merge = _ember['default'].merge;
  var setProperties = _ember['default'].setProperties;
  exports['default'] = _ember['default'].Service.extend({

    setupService: _ember['default'].observer('_UAParser', function () {
      var parser = get(this, '_UAParser');

      var browser = parser.getBrowser();
      var device = parser.getDevice();
      var engine = parser.getEngine();
      var os = parser.getOS();

      setProperties(this, merge({

        browser: {
          info: browser,
          isChrome: isEqual(browser.name, 'Chrome'),
          isEdge: isEqual(browser.name, 'Edge'),
          isFirefox: isEqual(browser.name, 'Firefox'),
          isIE: isEqual(browser.name, 'IE') || isEqual(browser.name, 'IE Mobile'),
          isSafari: isEqual(browser.name, 'Safari') || isEqual(browser.name, 'Mobile Safari')
        },

        device: {
          info: device,
          isConsole: isEqual(device.type, 'console'),
          isDesktop: !device.type,
          isMobile: isEqual(device.type, 'mobile'),
          isTablet: isEqual(device.type, 'tablet')
        },

        engine: {
          info: engine,
          isWebkit: isEqual(engine.name, 'WebKit')
        },

        os: {
          info: os,
          isAndroid: isEqual(os.name, 'Android'),
          isIOS: isEqual(os.name, 'iOS'),
          isLinux: isEqual(os.name, 'Linux'),
          isMacOS: isEqual(os.name, 'Mac OS'),
          isWindows: isEqual(os.name, 'Windows') || isEqual(os.name, 'Windows Phone') || isEqual(os.name, 'Windows Mobile')
        }

      }, parser));
    })

  });
});