define('ember-useragent/instance-initializers/fastboot/ember-useragent', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = _initialize;
  var assert = _ember['default'].assert;
  var setProperties = _ember['default'].setProperties;

  function _initialize(appInstance) {

    var UAParser = FastBoot.require('ua-parser-js'),
        userAgent = undefined,
        headers = undefined;

    var service = appInstance.lookup('service:userAgent');
    var fastbootService = appInstance.lookup('service:fastboot');

    headers = fastbootService.get('request.headers');
    userAgent = headers.get('user-agent');

    assert('No userAgent present in ember-useragent/instance-initializers/fastboot', userAgent);

    setProperties(service, {
      _UAParser: new UAParser(userAgent),
      userAgent: userAgent
    });
  }

  exports['default'] = {
    name: 'ember-useragent-fastboot',
    initialize: function initialize() {
      if (typeof FastBoot !== 'undefined') {
        _initialize.apply(undefined, arguments);
      }
    }
  };
});
/* global FastBoot */