define('ember-macaroni/macros/collection/find-from-collection-by-key', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = findFromCollectionByKey;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var emberArray = _ember['default'].A;

  /**
   * Returns the first item with a property matching the passed value from a dependent key.
   *
   * Ember.Object.extend({
   *   items: [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }],
   *   selectedId: 1,
   *   selectedItem: findFromCollectionByKey('items', 'id', 'selectedId') // { id: 1, name: 'foo' }
   * });
   *
   * @param {String} collectionKey The key name for the collection
   * @param {String} propName The key name for the property to find by
   * @param {String} valueKey The key name that returns the value to find
  */

  function findFromCollectionByKey(collectionKey, propName, valueKey) {
    return computed(collectionKey + '.@each.' + propName, valueKey, {
      get: function get() {
        return emberArray(_get(this, collectionKey)).findBy(propName, _get(this, valueKey));
      }
    });
  }
});