define('ember-macaroni/macros/truth/if-then-else-with-keys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = ifThenElseWithKeys;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;

  /**
   * Ternary conditional with dependent keys.
   *
   * Ember.Object.extend({
   *   isSelected: true,
   *   selectedText: 'Is Enabled',
  *    deselectedText: 'Is Disabled',
   *   displayText: ifThenElseWithKeys('isSelected', 'selectedText', 'deselectedText') // 'Is Enabled'
   * });
   *
   * @param {String} conditionalKey The key name for the conditional property
   * @param {String} trueKey The key name for the property to return when the conditional is true
   * @param {String} falseKey The key name for the property to return when the conditional is false
  */

  function ifThenElseWithKeys(conditionalKey, trueKey, falseKey) {
    return computed(conditionalKey, trueKey, falseKey, {
      get: function get() {
        var conditionalValue = _get(this, conditionalKey);
        var trueValue = _get(this, trueKey);
        var falseValue = _get(this, falseKey);

        return isPresent(conditionalValue) && !!conditionalValue ? trueValue : falseValue;
      }
    });
  }
});