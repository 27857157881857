define('ember-macaroni/macros/collection/collection-without-key', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = collectionWithoutKey;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var emberArray = _ember['default'].A;

  /**
   * Returns an array without an item by dependent key.
   *
   * Ember.Object.extend({
   *   items: [{ id: 1, name: 'foo' }, { id: 2, name: 'bar' }],
   *   selectedItem: { id: 1, name: 'foo' },
   *   notSelectedItems: collectionWithoutKey('items', 'selectedItem')
   * });
   *
   * @param {String} collectionKey The key name for the collection
   * @param {String} dependentKey The key name for the property to exclude
  */

  function collectionWithoutKey(collectionKey, dependentKey) {
    return computed(collectionKey + '.[]', dependentKey, {
      get: function get() {
        return emberArray(_get(this, collectionKey)).without(_get(this, dependentKey));
      }
    });
  }
});