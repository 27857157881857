define('ember-macaroni/macros/general/get-properties-by-keys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = getPropertiesByKeys;
  var computed = _ember['default'].computed;
  var getProperties = _ember['default'].getProperties;

  /**
   * Returns a POJO containing all the key-values that match the dependent keys.
   *
   * Ember.Object.extend({
   *   age: 5,
   *   name: 'foo',
   *   props: getPropertiesByKeys('age', 'name') // { age: 5, name: 'foo' }
   * });
   *
   * @param {...rest} dependentKeys Argument list of dependent keys
  */

  function getPropertiesByKeys() {
    for (var _len = arguments.length, dependentKeys = Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }

    var computedFunc = computed({
      get: function get() {
        return getProperties(this, dependentKeys);
      }
    });

    return computedFunc.property.apply(computedFunc, dependentKeys);
  }
});