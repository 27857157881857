define('ember-macaroni/macros/collection/reduce-collection-by-key', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = reduceCollectionByKey;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var getWithDefault = _ember['default'].getWithDefault;
  var emberArray = _ember['default'].A;

  /**
   * Combines the values of the enumerator into a single value, using a dependent key.
   *
   * Ember.Object.extend({
   *   items: [{ name: 'foo', age: 2 }, { name: 'bar', age: 5 }],
   *   selectedItem: reduceCollectionByKey('items', 'age', 0) // 7
   * });
   *
   * @param {String} collectionKey The key name for the collection
   * @param {String} dependentKey The key name for the property to reduce
   * @param {*} startValue The initial value
  */

  function reduceCollectionByKey(collectionKey, dependentKey) {
    var startValue = arguments.length <= 2 || arguments[2] === undefined ? 0 : arguments[2];

    return computed(collectionKey + '.@each.' + dependentKey, {
      get: function get() {
        return emberArray(_get(this, collectionKey)).reduce(function (total, current) {
          return total + parseFloat(getWithDefault(current, dependentKey, 0));
        }, startValue);
      }
    });
  }
});