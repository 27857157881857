define('ember-cli-file-picker/components/file-picker', ['exports', 'ember', 'ember-cli-file-picker/lib/helpers'], function (exports, _ember, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      observer = _ember.default.observer,
      bind = _ember.default.run.bind,
      htmlSafe = _ember.default.String.htmlSafe,
      isEmpty = _ember.default.isEmpty,
      $ = _ember.default.$;
  exports.default = Component.extend({
    classNames: ['file-picker'],
    classNameBindings: ['multiple:multiple:single'],
    accept: '*',
    multiple: false,
    preview: true,
    dropzone: true,
    progress: true,
    showProgress: false,
    hideFileInput: true,
    readAs: 'readAsFile',
    selectOnClick: true,
    count: 0,
    errors: [],
    progressValue: null,

    progressStyle: computed('progressValue', function () {
      var width = this.get('progressValue') || 0;

      return htmlSafe('width: ' + width + '%;');
    }),

    /**
     * When the component got inserted
     */
    didInsertElement: function didInsertElement() {
      if (this.get('hideFileInput')) {
        this.hideInput();
      }
      this.hidePreview();
      this.hideProgress();

      this.$('.file-picker__input').on('change', bind(this, 'filesSelected'));
    },

    willDestroyElement: function willDestroyElement() {
      this.$('.file-picker__input').off('change', bind(this, 'filesSelected'));
    },

    /**
     * When the file input changed (a file got selected)
     * @param  {Event} event The file change event
     */
    filesSelected: function filesSelected(event) {
      var files = event.target.files;
      if (files.length) {
        this.handleFiles(files);
      } else {
        this.clearPreview();
        this.set('progressValue', 0);
      }
    },

    handleFiles: function handleFiles(files) {
      var _this = this;

      if (typeof this.filesAreValid === 'function') {
        if (!this.filesAreValid(files)) {
          return;
        }
      }

      if (this.get('preview')) {
        this.updatePreview(files);
      }

      if (this.get('multiple')) {
        this.sendAction('filesLoaded', files);
      } else {
        if (this.get('readAs') === 'readAsFile') {
          this.sendAction('fileLoaded', files[0]);
        } else {
          (0, _helpers.readFile)(files[0], this.get('readAs'), bind(this, 'updateProgress')).then(function (file) {
            _this.sendAction('fileLoaded', file);
          });
        }
      }

      if (!this.get('hideFileInput')) {
        this.$('.file-picker__input').val(null);
      }
    },

    /**
     * Update preview
     * @param  {Array} files The selected files
     */
    updatePreview: function updatePreview(files) {
      if (this.get('multiple')) {
        // TODO
      } else {
        this.clearPreview();
        this.set('showProgress', true);

        (0, _helpers.readFile)(files[0], 'readAsDataURL', bind(this, 'updateProgress')).then(bind(this, 'addPreviewImage'));

        this.$('.file-picker__dropzone').hide();
      }

      this.$('.file-picker__preview').show();
    },

    addPreviewImage: function addPreviewImage(file) {
      var image = this.$('<img src="' + file.data + '" class="file-picker__preview__image ' + (this.get('multiple') ? 'multiple' : 'single') + '">');

      this.hideProgress();
      this.$('.file-picker__preview').append(image);
    },

    updateProgress: function updateProgress(event) {
      var loaded = event.loaded,
          total = event.total;


      var value = null;

      if (!isEmpty(loaded) && !isEmpty(total) && parseFloat(total) !== 0) {
        value = loaded / total * 100;
      }

      this.set('progressValue', value);
    },

    hideInput: function hideInput() {
      this.$('.file-picker__input').hide();
    },

    hidePreview: function hidePreview() {
      this.$('.file-picker__preview').hide();
    },

    hideProgress: function hideProgress() {
      this.set('showProgress', false);
    },

    clearPreview: function clearPreview() {
      this.$('.file-picker__preview').html('');
      this.hidePreview();
      this.$('.file-picker__dropzone').show();

      // reset
      this.set('removePreview', false);
    },

    removePreviewDidChange: observer('removePreview', function () {
      if (this.get('removePreview')) {
        this.clearPreview();
      }
    }),

    // handles DOM events
    // Trigger a input click to open file dialog
    click: function click(event) {
      if (this.get('selectOnClick') === true) {
        if (!$(event.target).hasClass('file-picker__input')) {
          this.$('.file-picker__input').trigger('click');
        }
      }
    },
    /* Drag'n'Drop events */
    dragOver: function dragOver(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (!this.get('dropzone')) {
        return;
      }

      event.dataTransfer.dropEffect = 'copy';
    },
    drop: function drop(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (!this.get('dropzone')) {
        return;
      }

      this.handleFiles(event.dataTransfer.files);
      this.set('count', 0);
      this.$().removeClass('over');
    },
    dragEnter: function dragEnter(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (!this.get('dropzone')) {
        return;
      }

      if (!this.get('multiple')) {
        this.clearPreview();
      }
      var count = this.incrementProperty('count');
      if (count === 1) {
        this.$().addClass('over');
      }
    },
    dragLeave: function dragLeave(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (!this.get('dropzone')) {
        return;
      }

      var count = this.decrementProperty('count');
      if (count === 0) {
        this.$().removeClass('over');
      }
    },

    /*
     * returns true if browser supportes progress element
     *
     * browser support overview:
     * http://caniuse.com/#feat=progress
     *
     * uses test from Modernizr
     * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/elem/progress-meter.js
     */
    isProgressSupported: _ember.default.computed(function () {
      return document.createElement('progress').max !== undefined;
    })
  });
});