define('ember-local-storage/mixins/object', ['exports', 'ember', 'ember-local-storage/mixins/storage', 'ember-local-storage/helpers/utils'], function (exports, _ember, _storage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      set = _ember.default.set;
  exports.default = Mixin.create(_storage.default, {
    _initialContent: {},
    _clear: function _clear() {
      set(this, 'content', {});
    },


    setUnknownProperty: _utils.saveIfChanged,
    set: _utils.saveIfChanged,
    setProperties: _utils.save
  });
});