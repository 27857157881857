define('ember-local-storage/adapters/local', ['exports', 'ember', 'ember-local-storage/adapters/base', 'ember-local-storage/helpers/storage', 'ember-local-storage/local/array'], function (exports, _ember, _base, _storage, _array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = _ember.default.get;
  exports.default = _base.default.extend({
    _storage: (0, _storage.getStorage)('local'),

    _getIndex: function _getIndex(type) {
      var indices = get(this, '_indices');

      if (!indices[type]) {
        indices[type] = _array.default.extend({ _storageKey: 'index-' + type }).create();
      }

      return indices[type];
    }
  });
});