define('ember-cli-autocomplete-input/components/autocomplete-input', ['exports', 'ember', 'ember-cli-autocomplete-input/templates/components/autocomplete-input', 'ember-cli-keyboard-nav/mixins/keyboard-nav'], function (exports, _ember, _emberCliAutocompleteInputTemplatesComponentsAutocompleteInput, _emberCliKeyboardNavMixinsKeyboardNav) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend(_emberCliKeyboardNavMixinsKeyboardNav['default'], {

    layout: _emberCliAutocompleteInputTemplatesComponentsAutocompleteInput['default'],

    didInsertElement: function didInsertElement() {
      this.bindKeys(this.$('input[type="text"]'));
    },

    // Attributes

    resultName: 'name',

    resultValue: 'value',

    results: [],

    highlightedResultIndex: -1,

    term: '',

    lastTerm: '',

    // Properties

    highlightedResult: computed('results.[]', 'highlightedResultIndex', function () {
      return this.get('results')[this.get('highlightedResultIndex')];
    }),

    hasResults: computed.notEmpty("results"),

    // Observers

    termDidChange: observer('term', function () {
      this.send('updateTerm', this.get('term'));
    }),

    // Keyboard Nav actions

    onEnterPress: function onEnterPress() {
      var result = this.get("results")[this.get("highlightedResultIndex")];

      if (result) {
        this.send("selectResult", result);
      }
    },

    onEscPress: function onEscPress() {
      this.send("clearSearch");
    },

    onDownPress: function onDownPress() {
      var index = 0;

      if (this.get("highlightedResultIndex") >= 0) {
        index = this.get("highlightedResultIndex") + 1;
      }

      if (index > this.get("results").length - 1) {
        index = 0;
      }

      this.set("highlightedResultIndex", index);
    },

    onUpPress: function onUpPress() {
      var lastItem = this.get("results").length - 1;
      var index = lastItem;

      if (this.get("highlightedResultIndex") >= 0) {
        index = this.get("highlightedResultIndex") - 1;
      }

      if (index < 0) {
        index = lastItem;
      }

      this.set("highlightedResultIndex", index);
    },

    actions: {
      selectResult: function selectResult(result) {
        this.sendAction('selectResult', result);
      },

      updateTerm: function updateTerm(term) {
        if (term !== this.get('lastTerm')) {
          this.set('lastTerm', term);
          this.sendAction('updateTerm', term);
        }
      },

      clearSearch: function clearSearch() {
        this.sendAction("clearSearch");
      }
    }

  });
});