define('ember-macaroni/macros/truth/is-equal-by-keys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = isEqualByKeys;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;

  /**
   * Strict equality using dependent keys.
   *
   * Ember.Object.extend({
   *   employeeId: 1
   *   selectedId: 1,
   *   isSelected: isEqualByKeys('employeeId', 'selectedId') // true
   * });
   *
   * @param {String} firstKey The key name for the first property
   * @param {String} secondKey The key name for the second property
  */

  function isEqualByKeys(firstKey, secondKey) {
    return computed(firstKey, secondKey, {
      get: function get() {
        return _get(this, firstKey) === _get(this, secondKey);
      }
    });
  }
});