define('ember-cli-swiper/components/swiper-slide', ['exports', 'ember-cli-swiper/templates/components/swiper-slide'], function (exports, _swiperSlide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _swiperSlide.default,
    classNames: ['swiper-slide']
  });
});