define('ember-cp-validations/-private/result', ['exports', 'ember', 'ember-cp-validations/validations/result-collection', 'ember-cp-validations/-private/internal-result-object'], function (exports, _ember, _resultCollection, _internalResultObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var get = _ember.default.get,
      set = _ember.default.set,
      isNone = _ember.default.isNone,
      isArray = _ember.default.isArray,
      computed = _ember.default.computed,
      setProperties = _ember.default.setProperties,
      getProperties = _ember.default.getProperties;
  var readOnly = computed.readOnly;


  /**
   * __PRIVATE__
   *
   * @module Validations
   * @class Result
   * @private
   */

  var Result = _ember.default.Object.extend({

    /**
     * @property model
     * @type {Object}
     */
    model: null,

    /**
     * @property attribute
     * @type {String}
     */
    attribute: '',

    /**
     * @property _promise
     * @async
     * @private
     * @type {Promise}
     */
    _promise: null,

    /**
     * The validator that returned this result
     * @property _validator
     * @private
     * @type {Validator}
     */
    _validator: null,

    /**
     * Determines if the _validations object is readOnly.
     *
     * This is needed because ResultCollections and global validation objects control their own
     * state via CPs
     *
     * @property _isReadOnly
     * @private
     * @readOnly
     * @type {Boolean}
     */
    _isReadOnly: computed('_validations', function () {
      var validations = get(this, '_validations');
      return validations instanceof _resultCollection.default || get(validations, 'isValidations');
    }).readOnly(),

    /**
     * @property isWarning
     * @readOnly
     * @type {Boolean}
     */
    isWarning: readOnly('_validator.isWarning'),

    /**
     * @property isValid
     * @readOnly
     * @type {Boolean}
     */
    isValid: readOnly('_validations.isValid'),

    /**
     * @property isInvalid
     * @readOnly
     * @type {Boolean}
     */
    isInvalid: readOnly('_validations.isInvalid'),

    /**
     * @property isValidating
     * @readOnly
     * @type {Boolean}
     */
    isValidating: readOnly('_validations.isValidating'),

    /**
     * @property isTruelyValid
     * @readOnly
     * @type {Boolean}
     */
    isTruelyValid: readOnly('_validations.isTruelyValid'),

    /**
     * @property isAsync
     * @readOnly
     * @type {Boolean}
     */
    isAsync: readOnly('_validations.isAsync'),

    /**
     * @property isDirty
     * @readOnly
     * @type {Boolean}
     */
    isDirty: readOnly('_validations.isDirty'),

    /**
     * @property message
     * @readOnly
     * @type {String}
     */
    message: readOnly('_validations.message'),

    /**
     * @property messages
     * @readOnly
     * @type {Array}
     */
    messages: readOnly('_validations.messages'),

    /**
     * @property error
     * @readOnly
     * @type {Object}
     */
    error: readOnly('_validations.error'),

    /**
     * @property errors
     * @readOnly
     * @type {Array}
     */
    errors: readOnly('_validations.errors'),

    /**
     * This hold all the logic for the above CPs. We do this so we can easily switch this object out with a different validations object
     * @property _validations
     * @private
     * @type {Result}
     */
    _validations: computed('model', 'attribute', '_promise', '_validator', function () {
      return _internalResultObject.default.create(getProperties(this, ['model', 'attribute', '_promise', '_validator']));
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (get(this, 'isAsync') && !get(this, '_isReadOnly')) {
        this._handlePromise();
      }
    },
    update: function update(result) {
      var validations = get(this, '_validations');
      var validator = get(this, '_validator');

      var _getProperties = getProperties(this, ['model', 'attribute']),
          model = _getProperties.model,
          attribute = _getProperties.attribute;

      if (isNone(result)) {
        this.update(false);
        return;
      }

      if (get(result, 'isValidations')) {
        set(this, '_validations', result);
      } else if (isArray(result)) {
        var validationResultsCollection = _resultCollection.default.create({
          attribute: attribute,
          content: result.map(function (r) {
            return Result.create({
              attribute: attribute,
              model: model,
              _validator: validator,
              _validations: r
            });
          })
        });
        set(this, '_validations', validationResultsCollection);
      } else if (!get(this, '_isReadOnly')) {
        if (typeof result === 'string') {
          setProperties(validations, {
            message: result,
            isValid: false
          });
        } else if (typeof result === 'boolean') {
          set(validations, 'isValid', result);
        } else if ((typeof result === 'undefined' ? 'undefined' : _typeof(result)) === 'object') {
          setProperties(validations, result);
        }
      }
    },
    _handlePromise: function _handlePromise() {
      var _this = this;

      get(this, '_promise').then(function (result) {
        return _this.update(result);
      }, function (result) {
        return _this.update(result);
      }).catch(function (reason) {
        // TODO: send into error state
        throw reason;
      });
    }
  });

  exports.default = Result;
});