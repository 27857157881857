define('ember-macaroni/macros/truth/if-then-else-with-values', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = ifThenElseWithValues;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;

  /**
   * Ternary conditional.
   *
   * Ember.Object.extend({
   *   isSelected: true,
   *   displayText: ifThenElseWithKeys('isSelected', 'Is Enabled', 'Is Disabled') // 'Is Enabled'
   * });
   *
   * @param {String} conditionalKey The key name for the conditional property
   * @param {String} trueValue The value to return when the conditional is true
   * @param {String} falseValue The value to return when the conditional is false
  */

  function ifThenElseWithValues(conditionalKey) {
    var trueValue = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];
    var falseValue = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    return computed(conditionalKey, {
      get: function get() {
        var conditionalValue = _get(this, conditionalKey);

        return isPresent(conditionalValue) && !!conditionalValue ? trueValue : falseValue;
      }
    });
  }
});