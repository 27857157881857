define('ember-useragent/instance-initializers/ember-useragent', ['exports', 'ember', 'ua-parser-js'], function (exports, _ember, _uaParserJs) {
  exports.initialize = _initialize;
  var assert = _ember['default'].assert;
  var setProperties = _ember['default'].setProperties;

  function _initialize(appInstance) {

    var userAgent = undefined;

    var service = appInstance.lookup('service:userAgent');

    if (window && window.navigator) {
      userAgent = window.navigator.userAgent;
    }

    assert('No userAgent present in ember-useragent/instance-initializers/ember-useragent', userAgent);

    setProperties(service, {
      _UAParser: new _uaParserJs['default'](userAgent),
      userAgent: userAgent
    });
  }

  exports['default'] = {
    name: 'ember-useragent-browser',
    initialize: function initialize() {
      if (typeof FastBoot === 'undefined') {
        _initialize.apply(undefined, arguments);
      }
    }
  };
});